// ToDO: ALL THIS FROM HERE TO THE LINE OF "-" NEEDS TO BE MOVED TO THE BACKEND
/*
 * Case Type constants to avoid using magic strings in components.
 * The ids from the server should stay the same
 * (safer to use these rather than labels which may change)
 */
export enum CaseTypeIdEnum {
  General = 'a0454696-0660-4951-b5a1-12f021c6a474',
  CrimeVictim = '89e9c662-54d9-4685-bbe0-c299e46ffba7'
}

/**
 * Selection dropdown default values that are represented by an id.
 */
export enum DropDownDefaultValues {
  Age = 'aded77cc-4511-44e9-ab9f-80ca5b3740af',
  Race = '98304130-0b11-482f-b6d2-97941a461daf',
  Gender = '126c6749-4b12-4a6b-9a63-17b88ea397a7',
}

/**
 * Claim Type ID Enums
 * ToDo: To be replaced with Ryan's backend enums
 */
export const enum ClaimTypeIdEnum {
  None = 'null',
  PersonalInjury = '17df51db-5f6a-446d-9c6c-f5870e1923c5',
  Death = 'f98626f1-082e-43c7-87f3-fef28fac0560',
  Prisoner = '5acbb51b-0c60-4713-9e6c-38b733b45a98',
  Contract = 'ce937583-fd84-433a-853f-8719080be0ee',
  LapsedAppropriation = '7e8457ee-3373-41db-a3cb-ff9a1a039dcf',
  BackSalary = '3ea9f75a-b673-4c13-9fe9-2f8148c37515',
  LostWarrant = 'df57b345-0768-4442-a98e-686a83023ed5',
  Refund = '14a1eaf8-d0ee-4b6d-b267-5c33fd362033',
  PropertyDamage = 'b575e8c6-a9d0-4699-9032-a7ad90617091',
  Miscellaneous = '6220ea6e-2296-48b3-b959-0a764df09f57',
  PoliceFireNatGuard = '195d57d9-ec71-4726-81d5-856ada3cee8e',
  MedicalVendor = '34f24f91-8a22-4e84-a88a-84b50eb5b8ee',
  CertificateOfInnocence = '47053200-5e0d-4828-9ab7-5575c91ed23f',
}
// --------------------------------------------------------------------------------------------

/**
 * The application title used as in all route titles.
 */
export const APP_TITLE = 'IL SOS';

/**
 * The maximum number of counties the user is allowed to add
 * in the "Case Information" section.
 */
export const MAX_COUNTIES = 1;

/**
 * The labels for the Claimant section of the Crime Victim form.
 */
export const CV_CLAIMANT_LABELS = {
  minors: 'Number of minors (17 years old or younger) and/or legally declared adult dependents served by this claim in addition to the Claimant?',
  age: 'Age at the time of the Crime',
  victimName: 'Enter Victim Name (if different from Claimant)',
};

/**
 * Regex pattern for a valid case number.
 */
export const CASE_NUMBER_REGEX = `^\\d{4}(CV|CC|cv|cc)\\d{4}$`;
/**
 * Regex pattern for a valid SSN or TIN.
 */
export const CASE_SSN_AND_TIN_REGEX = `^(?:(?!000|666|9\\d{2})\\d{3}[-\\s]?(?!00)\\d{2}[-\\s]?(?!0000)\\d{4}|(?!00)\\d{2}[-\\s]?\\d{7})$`
/**
 * Regex pattern for a valid attention line.
 */
export const CASE_ATTENTION_LINE_REGEX = `^(?!.* {2})[A-Za-z0-9 .-]{1,50}$`;
/**
 * Regex pattern for a valid phone number.
 */
export const CASE_PHONE_NUMBER_REGEX = `^(?:\\+1\\s?)?(?:\\(?\\d{3}\\)?[\\s.-]?)?\\d{3}[\\s.-]?\\d{4}$`;
/**
 * Regex pattern for a valid warrant number.
 */
export const ALPHA_NUMERIC_REGEX = `^[a-zA-Z0-9]+$`;
